@import '../../scss/abstracts/variables';

.content {
    padding: 2.5rem 0;
}

.container-video {
    max-width: 1440px;
    margin: 0 auto;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
}
