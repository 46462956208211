@import "../../scss/abstracts/variables";

.polls-button{
    display: flex;
    text-align:center;
    justify-content:center;
}

.polls-icon{
    align-self: center;
}

.polls-container{
    display:flex;
    flex-direction:row;
}