@import "../../scss/abstracts/variables";

.footer {
  background: $gray-100;
}

.logo {
  max-width: 300px;
  margin: 0 auto 5rem;
}
