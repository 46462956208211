@import "../../../scss/abstracts/variables";

@import url(https://fonts.googleapis.com/css?family=Lato:400,700);

$other_message: #B19CD7;
$my_message: #FFB6C1;

*,
*:before,
*:after {
  box-sizing: border-box;
}

.chat-container {
  margin: 0 auto;
  width: 750px;
  background: #444753;
  border-radius: 5px;
}

.chat {
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.3);
  border-radius: $chat_video_border_radius;

  @media screen and (min-width: $lg-min-width) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.border-2 {
  border-width: 2px;
}

.chat .chat-header {
  color: #92959e;
}

.chat-body {
  overflow-y: scroll;
  max-height: 410px;
  min-height: 200px;
}

.message {
  color: white;
  padding: 0.5rem 0.75rem;
  border-radius: 7px;
  margin-bottom: 1.5rem;
  max-width: 70%;
  position: relative;
  background: $other_message;
  font-size: $small-font-size;
  &::after {
    bottom: 100%;
    left: 7%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-bottom-color: $other_message;
    border-width: 10px;
    margin-left: -10px;
  }
}

.my-message {
  background: $my_message;
  &::after {
    left: 93%;
    border-bottom-color: $my_message;
  }
}

.chat .chat-message .fa-file-o,
.chat .chat-message .fa-file-image-o {
  color: gray;
  cursor: pointer;
}

.chat .chat-message button {
  float: right;
  color: $other_message;
  font-size: 16px;
  text-transform: uppercase;
  border: none;
  cursor: pointer;
  font-weight: bold;
  background: #f2f5f8;
}
.chat .chat-message button:hover {
  color: #75b1e8;
}

.online,
.offline,
.me {
  margin-right: 3px;
  font-size: 10px;
}

.online {
  color: #86bb71;
}

.offline {
  color: #e38968;
}

.me {
  color: #94c2ed;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.textarea-chat, .chat-submit {
  font-size: $small-font-size;
  resize: none;
}

.chat-actions {
  position: relative;
  .emoji-picker {
    position: absolute;
    bottom: 0;
    z-index: 999;
  }
}