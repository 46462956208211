.caption-wrapper {
    position: relative;
    padding-bottom: 6%;
    iframe {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        border-radius: 1.25rem;
    }
}
