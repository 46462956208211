.sponsor-group-title {
    position: relative;
    padding-bottom: 0.5rem;
    color: #808080;
    &::after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        background: #808080;
    }
}

.sponsor-group-col {
    max-width: 350px;
}