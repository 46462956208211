@import '../../../scss/abstracts/mixins';
@import '../../../scss/abstracts/variables';

.iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    @include video-player;
}
