.iframe-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    transform: translateZ(0);

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
    }

    &.has-modal {
        cursor: pointer;
        iframe {
            pointer-events: none;
        }
    }

    &.has-height {
        padding-bottom: 0;
    }
}

.modal-dialog {
    min-width: 70%;
    :global {
        .modal-content {
            background: none;
            border: 0 none;
            .modal-header {
                border: 0 none;
                padding: 0 0 1rem 0;
                background: none;
                button {
                    border: 0 none;
                    box-shadow: none;
                    &:focus {
                        border: 0 none;
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
            .modal-body {
                padding: 0;
            }
        }
    }
}
