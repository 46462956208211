// Follow https://www.learnhowtoprogram.com/user-interfaces/building-layouts-preprocessors/7-1-sass-architecture
// Variables and mixins must be included before Bootstrap so that the variables can override the !default in Bootstrap.

@import "abstracts/variables";
@import "vendors/bootstrap";
@import "abstracts/mixins";

// Base Styles
@import "base/typography";

// Layout Styles
@import "layout/grid";
@import "layout/base";
@import "layout/utilities";

// Component Styles
@import "components/images";
@import "components/buttons";
@import "components/tables";

// Other Styles
