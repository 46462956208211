@import "../../scss/abstracts/variables";

.link {
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: $white;
    font-size: 1.5rem;
}