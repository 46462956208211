@import "../abstracts/_mixins.scss";

.h-sm-100 {
  @media screen and (min-width: $sm-min-width) {
    height: 100% !important;
  }
}

.shadow-xl {
  box-shadow: $box-shadow-xl !important;
}

.shadow-input {
  box-shadow: $box-shadow-input !important;
}

.blur {
  -webkit-filter: blur(3px);
  -moz-filter: blur(3px);
  -o-filter: blur(3px);
  -ms-filter: blur(3px);
  filter: blur(3px);
  opacity: 0.95;
}
