@import '../../scss/abstracts/variables';

.countdown-number {
    text-align: center;
    font-size: 4.5rem;
}

.countdown-title {
    font-size: 2rem;
    text-align: center;
}

@media screen and (max-width: $md-min-width - 1px) {
    .countdown-number {
        font-size: 2.25rem;
    }
    .countdown-title {
        font-size: 1rem;
    }
}
