@import "../../scss/abstracts/variables";

.answered-question {
    label {
        transition: 0.3s ease all;
    }

    .correct-answer label {
        color: green;
    }
    .incorrect-answer-selected label {
        color: $danger;
    }
}