@import "../abstracts/variables";

.btn-white {
    color: $primary;
    &:hover {
        color: $primary;
    }
}

.btn-plain {
    padding: 0;
    outline: 0;
    box-shadow: none;
    &:focus {
        box-shadow: none;
    }
}