[class^='col-'],
.col {
    margin-bottom: 1rem;
    position: relative;
    z-index: 10;
}

// Make 5ths columns

.col-md-5th {
    @include make-col-ready;

    @media screen and (min-width: $md-min-width) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.col-lg-5th {
    @include make-col-ready;

    @media screen and (min-width: $lg-min-width) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

@media screen and (min-width: $md-min-width) {
    .offset-md-2-5 {
        margin-left: 20.83%;
    }

    .offset-md-1-5 {
        margin-left: 12.5%;
    }

    .offset-md-4-5 {
        margin-left: 37.5%;
    }
}
@media screen and (min-width: $lg-min-width) {
    .offset-lg-2-5 {
        margin-left: 20.83%;
    }

    .offset-lg-1-5 {
        margin-left: 12.5%;
    }

    .offset-lg-4-5 {
        margin-left: 37.5%;
    }
}

.flex-row-wrap {
    flex-flow: row wrap;
}

@media screen and (min-width: $xl-min-width) {
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl {
        padding: 0;
    }
}
