.body-base {
    font-size: 1rem;
}

.body-large {
    font-size: 1.5rem;
}

.body-small {
    font-size: 0.875rem !important;
}

.line-height-1 {
    line-height: 1;
}

.no-wrap {
    white-space: nowrap;
}
