@import '../../../scss/abstracts/variables';

.iframe-wrapper {
    height: 100%;
    iframe {
        width: 100%;
        height: 100%;
        min-height: 350px;
        @media screen and (min-width: $xxl-min-width) {
            min-height: 500px;
        }
    }
}
