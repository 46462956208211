@import '../../scss/abstracts/mixins';
@import '../../scss/abstracts/variables';

.video-container {
    position: relative;
    padding-bottom: 56.25%; // 16:9
    transform: translate3d(0, 0, 0);

    &.no-padding-bottom {
        padding-bottom: 0;
    }
}

.view-count {
    position: absolute;
    top: 1rem;
    left: 1rem;
    font-size: 12px;
    color: $white;
    background: $red;
    padding: 0.25rem;
    border-radius: 0.25rem;
}

.video-border-radius {
    border-radius: $chat_video_border_radius;
}

.no-border-radius {
    border-radius: 0;
}
