@mixin video-player {
    border-radius: $chat_video_border_radius;

    &.no-border-radius {
        border-radius: 0;
    }

    &.post-show {
        border-radius: $chat_video_border_radius;
    }

    @media screen and (min-width: $lg-min-width) {
        border-radius: $chat_video_border_radius 0 0 $chat_video_border_radius;
    }
}
