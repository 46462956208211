@import '../../../scss/abstracts/variables';

.sponsor-title {
    flex-wrap: wrap;
    flex: 0 0 49%;
    font-size: $font-size-sm;
}

.sponsor-title-larger-column:nth-of-type(odd) {
    margin-right: 2%;
}

.line-left {
    @media screen and (min-width: $lg-min-width) {
        border-left: 2px solid green;
    }
}
