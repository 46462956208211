@import "../abstracts/variables";

html.loading {
  height: 100%;
  body {
    height: 100%;
    background: $white;
    #root {
      height: 100%;
    }
  }
}

.bg-none {
  background: none !important;
}
