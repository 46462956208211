// stylelint-disable number-leading-zero

// Place Bootstrap overrides and custom variables in this file.
// NOTE: Only override a Bootstrap variable if it needs to be different than default.

// Color System
$gray-100: #f1f2f2;
$gray-200: #f9fcfc;
$gray-300: #a7a7a7;
$gray-400: #7f8e99;
$gray-500: #666666;
$gray-700: #495057;
$gray-900: #34393c;
$gray-950: #202833;
$gray-975: #181e26;
$gray-1000: #24272a;
$black: #000000;

$primary: #6633cc;
$secondary: #863289;

$blue: #216bb4;
$darkBlue: #134679;
$white: #ffffff;
$red: #f01919;
$danger: #e34843;
$green: #2be83e;
$darkGreen: #4daa61;
$yellow: #ecf019;

$body-color: $gray-1000;

$theme-colors: () !default;
// Adding colors here will auto generate classes for them, e.g. text-{color}, bg-{color}, etc.
$theme-colors: map-merge(
  (
    "body": $body-color,
    "blue": $blue,
    "light-gray": $gray-200,
    "gray": $gray-400,
    "medium": $gray-500,
    "white": $white,
    "green": $green,
    "dark-green": $darkGreen,
    "red": $red,
    "yellow": $yellow,
  ),
  $theme-colors
);

// Options
$enable-pointer-cursor-for-buttons: true;

// Spacing
$spacer: 1rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 2,
    ),
    6: (
      $spacer * 2.5,
    ),
    "6-5": (
      $spacer * 2.5,
    ),
    7: (
      $spacer * 3,
    ),
    8: (
      $spacer * 3.5,
    ),
    9: (
      $spacer * 4,
    ),
    13: (
      $spacer * 6,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: ();
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

// Body
$body-bg: $gray-200;

// Links
$link-decoration: none;

// Grid Containers
$xxl-min-width: 1440px;
$xl-min-width: 1250px;
$lg-min-width: 1025px;
$md-min-width: 768px;
$sm-min-width: 576px;

$grid-breakpoints: (
  xs: 0,
  sm: $sm-min-width,
  md: $md-min-width,
  lg: $lg-min-width,
  xl: $xl-min-width,
);

// Grid Columns
$grid-gutter-width: 24px;
$content-padding-y: 2.5rem;

$container-max-width: 1080px + $grid-gutter-width; // 1200 plus grid padding.

$container-max-widths: (
  xl: $container-max-width,
);

// Components
$line-height-sm: 1;

$border-width: 1px;

$border-radius: 4px;
$border-radius-lg: 4px;
$border-radius-sm: 4px;

$box-shadow-dimensions: 0px 2px 4px;
$box-shadow-lg-dimensions: 2px 2px 7px;
$box-shadow-xl-dimensions: 2px 2px 7px;
$box-shadow-input: 0 0 10px 0 rgba(0, 0, 0, 0.15);

$box-shadow: $box-shadow-dimensions rgba(0, 0, 0, 0.5);
$box-shadow-lg: $box-shadow-lg-dimensions rgba(0, 0, 0, 0.5);
$box-shadow-xl: $box-shadow-xl-dimensions rgba(0, 0, 0, 0.15);

$component-active-bg: $primary;

// Typography
$font-family-base: Roboto, helvetica, arial, open-sans; // stylelint-disable-line value-keyword-case
$montserrat-font-family: montserrat, $font-family-base; // stylelint-disable-line value-keyword-case

$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.125;

$font-weight-normal: 400;

$font-weight-bold: 700;

$line-height-base: 1.5;

$h1-font-size: $font-size-base * 2.5; // 40px at base 16px
$h2-font-size: $font-size-base * 1.875; // 30px at base 16px
$h3-font-size: $font-size-base * 1.625; // 26px at base 16px
$h4-font-size: $font-size-base * 1.375; // 22px at base 16px
$h5-font-size: $font-size-base * 1.25; // 20px at base 16px
$h6-font-size: $font-size-base;

$headings-margin-bottom: 1rem;
$headings-font-weight: 700;
$headings-line-height: 1.3;

$small-font-size: 0.833rem;
$large-font-size: 2.986rem;

$light-opacity: 0.7;

$text-muted: $gray-400;

// Tables
$table-cell-padding: 1.25rem;
$table-head-bg: $gray-200;

$table-dark-bg: $gray-950;

// Buttons + Forms
$input-color: $gray-400;
$input-border-color: $white;
$input-box-shadow: $box-shadow-input;
$input-focus-border-color: $white;
$input-focus-box-shadow: $box-shadow-input;

$input-btn-padding-y: 0.75rem;
$input-btn-padding-x: 0.75rem;
$input-btn-font-family: null;
$input-btn-font-size: $font-size-base;
$input-btn-line-height: $line-height-base;

$input-btn-padding-y-lg: 0.345rem;
$input-btn-padding-x-lg: 1.5rem;

$input-btn-padding-y-sm: 0.5rem;
$input-btn-padding-x-sm: 0.5rem;

$input-btn-border-width: $border-width;

$input-btn-focus-width: 0.2rem;
$input-btn-focus-color: rgba($component-active-bg, 0.25);
$input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color;

// Buttons
$btn-font-family: $input-btn-font-family;
$btn-font-weight: $font-weight-normal;
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: $input-btn-padding-x;
$btn-font-size: $input-btn-font-size;

$btn-line-height: $input-btn-line-height;
$btn-border-radius: 0;
$btn-white-space: null;

$btn-focus-box-shadow: $input-btn-focus-box-shadow;
$btn-disabled-opacity: 0.65;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125);

$btn-border-width: $input-btn-border-width;

$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

// Forms
$input-placeholder-color: rgba(0, 0, 0, 0.212959);

// Navbar
$navbar-padding-y: 0;
$navbar-padding-x: 0;
$navbar-light-color: $gray-900;
$navbar-light-active-color: $gray-1000;
$navbar-dark-color: $white;
$navbar-nav-link-padding-x: 1.25rem;
$navbar-nav-link-padding-y: 1.25rem;

// Dropdowns
$dropdown-min-width: 7rem;
$dropdown-padding-y: 1.25rem;
$dropdown-border-radius: 0.625rem;
$dropdown-font-size: $small-font-size;

// Pagination
$pagination-bg: none;
$pagination-color: $body-color;
$pagination-border-width: 0;
$pagination-border-color: transparent;

$pagination-hover-bg: none;

$pagination-active-bg: $gray-200;

$pagination-disabled-bg: none;
$pagination-disabled-border-color: none;


// Custom
$chat_video_border_radius: 1.25rem;