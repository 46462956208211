@import '../../scss/abstracts/variables';

.title-wrapper {
    position: relative;

    &.with-underline {
        margin-bottom: 2rem;
        .title {
            padding-bottom: 1rem;
        }
    }

    .title {
        position: relative;
        padding-bottom: 0;
        a {
            text-decoration: none;
        }

        &.side-lines {
            white-space: normal;
            text-align: center;
            @media screen and (min-width: $md-min-width) {
                white-space: nowrap;
            }
        }
    }
    .title-underline {
        content: '';
        display: block;
        position: absolute;
        height: 0.25rem;
        width: 125px;
        background: inherit;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
    }
    .title-separator {
        height: 6px;
        border: 0 none;
        border-top-width: 1px;
        border-bottom-width: 1px;
        border-style: solid;
        border-color: $white;
        width: 100%;
        display: none;

        @media screen and (min-width: $sm-min-width) {
            display: block;
        }
    }
}
